import React from 'react';
import WhawedoImg from '../../src/Assests/images/banner.png';

function WhatWeDo() {
    return (

        <section className="sec01">
            <div className='container'>
                <artical className='row sec01_content'>
                <div className='col-6 sec_01_left text-left'>
                    <h2 className='heading'>What We Do</h2>
                    <p className='sec_bold'>We are an independent financial services firm specializing in mergers and acquisitions and capital raising.</p>
                    <p>Our clients include corporations, investors and entrepreneurs.</p>
                    <a className='lear_mor_link'>Learn More</a>
                </div>
                <div className='col-6 sec_01_right'>
                   <img src={WhawedoImg} alt=''/>
                </div>
                </artical>
                
            </div>
        </section>

    );
}
export default WhatWeDo;