import React from 'react';
import NewsData from '../Component/NewsData';

function News() {
    return (

        <section className="sec01 sec07">
            <div className='container '>
                <h2 className='heading'>OUR LATEST PUBLICATIONS</h2>
                <div className='card-deck'>
                    {
                        NewsData.map((val, ind) => {
                            return (

                                <div className="card text-left" key={ind}>
                                    <img className="card-img-top" src={val.imgsrc} alt="news" />
                                    <div className="card-body">
                                        <p className="card-text"><a href='#'>{val.cat} </a></p>
                                        <h5 className="card-title">{val.title}</h5>
                                        <p className="card-text">{val.dicription}</p>
                                        <p>
                                            <a href='#'>PRESS RELEASE </a>  |  {val.time}
                                        </p>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                <p className="text-center">  <a className='lear_mor_link'>BROWSE ALL PUBLICATIONS</a></p>
            </div>
        </section>

    );
}
export default News;