import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// import required modules
import { Navigation, Pagination, Mousewheel, Keyboard } from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import TransectionData from '../Component/TransectionData';

function Transection() {
    return (
        <>
        <section className="sec02">
            <h2 className="heading">Our Latest Transactions</h2>
        <div className="container">
        <Swiper
          slidesPerView={3}
          spaceBetween={30}
          freeMode={true}
          cssMode={true}
        navigation={true}
        mousewheel={true}
        keyboard={true}
        modules={[Navigation, Pagination, Mousewheel, Keyboard]}
          pagination={{
            type: "progressbar",
          }}
          breakpoints={{
            1200: {
                slidesPerView: 3,
                slidesPerGroup: 3,
            },
            768: {
              slidesPerView: 2,
              slidesPerGroup: 2,
          },
          320: {
            slidesPerView: 1,
            slidesPerGroup: 1,
        },
        }}
          className="mySwiper"
        >
           {
              TransectionData.map((val, ind) => {
                return (
                  <SwiperSlide key={ind} >
                    <img src={val.imgsrc} />
                  </SwiperSlide>
                )
              })
            }
        </Swiper>
        </div>
        <p className="text-center">  <a className='lear_mor_link'>VIEW ALL TRANSACTIONS</a></p>
        </section>
        
       
      </>

    );
}
export default Transection;