import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/scrollbar";
import "swiper/css/navigation";
import "swiper/css/pagination";

import { Keyboard, Navigation } from "swiper";

import TeamData from '../Component/TeamData';

import transection01 from '../../src/Assests/images/team01.png';
import transection02 from '../../src/Assests/images/team02.png';


function OurTeam() {
  
    return (

        <section className="sec01 sec04">
            <div className='container-fluid'>
                <artical className='row sec01_content'>
                    <div className='col-6 sec_01_left text-left'>
                        <h2 className='heading'>Meet the team</h2>
                        <p className='sec_bold'>We are an independent financial services firm specializing in mergers and acquisitions and capital raising.</p>
                        <a className='lear_mor_link'>Learn More</a>
                    </div>
                    <div className='col-6 '>
                        <Swiper
                            slidesPerView={1}
                            centeredSlides={false}
                            slidesPerGroupSkip={1}
                            grabCursor={true}
                            spaceBetween={30}
                            keyboard={{
                                enabled: true,
                            }}
                            breakpoints={{
                                769: {
                                    slidesPerView: 2,
                                    slidesPerGroup: 2,
                                },
                            }}
                            navigation={true}
                            modules={[Keyboard, Navigation]}
                            className="mySwiper"
                        >
                            {
                                TeamData.map((val, ind) => {
                                    return (
                                        <SwiperSlide key={ind} >
                                            <img src={val.imgsrc} />
                                            <h3>{val.title}</h3>
                                            <p>{val.designation}</p>
                                        </SwiperSlide>
                                    )
                                })
                            }
                           
                        </Swiper>
                    </div>
                </artical>

            </div>
        </section>

    );
}
export default OurTeam;