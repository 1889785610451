import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// import required modules
import { Navigation,  Keyboard } from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import testimonialimg from '../../src/Assests/images/testimonial.png';

import ClientData from './ClientData';


function Testimonial() {
  return (

    <>
      <section className=" sec06">
        <h2 className="heading">A few of the companies we have worked with</h2>
        <div className="container">
          <Swiper
            slidesPerView={1}
            centeredSlides={false}
            slidesPerGroupSkip={1}
            grabCursor={true}
            spaceBetween={30}
            keyboard={{
                enabled: true,
            }}
            
            navigation={true}
            modules={[Keyboard, Navigation]}
            className="mySwiper"
          >
            {
              ClientData.map((val, ind) => {
                return (
                  <SwiperSlide  >
                    <img src={testimonialimg} />
                  </SwiperSlide>
                )
              })
            }

          </Swiper>
        </div>
        </section>


    </>

  );
}
export default Testimonial;