import React from 'react';
import { NavLink, Link } from 'react-router-dom';
 import footerlogo from '../../src/Assests/images/footer_logo.png';
import link from '../../src/Assests/images/link.png';
import twt from '../../src/Assests/images/twt.png';
import email from '../../src/Assests/images/email.png'; 

function Footer() {
    return (

        <div className="footer">
            <div className='container'>
                <div className='row'>
                    <div className='col-sm-6 col-md-3'>
                        <img src={footerlogo} />
                    </div>
                    <div className='col-sm-6 col-md-3'>
                        <h3>Quick Links</h3>
                        <ul className='list-unstyled'>
                            <li>
                                <a >Services</a>
                            </li>
                            <li>
                                <a >Press Releases</a>
                            </li>
                            <li>
                                <a >Blog</a>
                            </li>
                            <li>
                                <a >News</a>
                            </li>

                        </ul>
                    </div>
                    <div className='col-sm-6 col-md-3'>
                        <h3>POPULAR LINKS</h3>
                        <ul className='list-unstyled'>
                            <li>
                                <a >Services</a>
                            </li>
                            <li>
                                <a >Press Releases</a>
                            </li>
                            <li>
                                <a >Blog</a>
                            </li>
                            <li>
                                <a >News</a>
                            </li>

                        </ul>
                    </div>
                   
                    <div className='col-sm-6 col-md-3'>
                        <ul className='list-unstyled'>
                            <img src={link} /> <img src={twt} /> <img src={email} />
                        </ul>
                    </div>
                </div>
            </div>
        </div>

    );
}
export default Footer;