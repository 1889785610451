import React from 'react';

const handleScrollUp = () => {
    window.scrollTo( { left: 0, top: 0, behavior: 'smooth' } )
}
 
function BacktoTop() {
    
    return (

        <section className="sec02 sec05">
           <p className="text-center">  <a onClick={handleScrollUp}  className='lear_mor_link'>BACK TO TOP</a></p>
        </section>

    );
}
export default BacktoTop;