
import React from 'react';
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle";
import './App.css';
import '../src/Assests/App.scss';
import TopHeader from '../src/Component/Header';
import WhatWeDo from '../src/Component/WhatWeDo';
import Transection from '../src/Component/Transection';
import OurMission from '../src/Component/OurMission';
import OurTeam from '../src/Component/OurTeam';
import Client from '../src/Component/Client';
import Testimonial from '../src/Component/Testimonial';
import News from '../src/Component/News';
import BacktoTop from '../src/Component/BackToTop';
import Footer from '../src/Component/Footer';

function App() {

  return (
    <div className="App">
      <TopHeader></TopHeader>
      <WhatWeDo></WhatWeDo>
      <Transection></Transection>
      <OurMission></OurMission>
      <OurTeam></OurTeam>
      <Client></Client>
      <Testimonial></Testimonial>
      <News></News>
      <BacktoTop></BacktoTop>
      <Footer></Footer>
    </div>
  );
}

export default App;
