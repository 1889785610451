import transection01 from '../../src/Assests/images/team01.png';
import transection02 from '../../src/Assests/images/team02.png';


const TeamData = [
    {
        imgsrc: transection01,
        title: "Ziad Awad",
        designation: "Cheif Executive Officer"
    },
    {
        imgsrc: transection02,
        title: "Marc Nassim",
        designation: "Partner & Managing Director"
    },
    {
        imgsrc: transection01,
        title: "Ziad Awad",
        designation: "Cheif Executive Officer"
    },
    {
        imgsrc: transection02,
        title: "Marc Nassim",
        designation: "Partner & Managing Director"
    },
    
]
export default TeamData;