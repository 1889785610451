import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// import required modules
import { Navigation, Pagination, Mousewheel, Keyboard , Autoplay, Speed} from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

//import "swiper/css/styles.css";


import ClientData from '../Component/ClientData';

function Client() {
  return (

    <>
      <section className="sec02 sec05">
        <h2 className="heading">A few of the companies we have worked with</h2>
        <div className="container-fluid">
          <Swiper
            slidesPerView={7}
            spaceBetween={15}
            cssMode={true}
            navigation={true}
            mousewheel={true}
            keyboard={true}
            loop={true}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            modules={[Navigation, Pagination, Mousewheel, Keyboard, Autoplay ]}
            
            breakpoints={{
              1200: {
                slidesPerView: 7,
                slidesPerGroup: 7,
              },
              768: {
                slidesPerView: 4,
                slidesPerGroup: 4,
              },
              320: {
                slidesPerView: 3,
                slidesPerGroup: 3,
              },
            }}
            className="mySwiper"
          >
            {
              ClientData.map((val, ind) => {
                return (
                  <SwiperSlide key={ind} >
                    <img src={val.imgsrc} />
                  </SwiperSlide>
                )
              })
            }

          </Swiper>
        </div>
        <p className="text-center">  <a className='lear_mor_link'>VIEW ALL TRANSACTIONS</a></p>
      </section>


    </>

  );
}
export default Client;