import React from 'react';
import logo from '../../src/Assests/images/logo.png';
import search_icon from '../../src/Assests/images/search.png';
import menu from '../../src/Assests/images/menu.png';

const scrollDown = () => {
    window.scrollTo({ left: 0, top: 900, behavior: 'smooth' })
}

function TopHeader() {

    return (

        <div className="home_banner">
            <nav className="navbar">
                <div className="container-fluid">
                    <div class="row justify-content-between">
                        <div class="col-4 text-left">
                            <a className="navbar-brand" href="#">
                                <img src={logo} alt="" />
                            </a>
                        </div>
                        <div class="col-4 text-right">
                            <ul className="navbar-nav ">

                                <li className="nav-item">
                                    <a className="nav-link" href="#">
                                        <img className='search_icon' src={search_icon} />
                                    </a>
                                </li>
                                <li className="nav-item dropdown">
                                    <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        Eng
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#">
                                        <img className='search_icon' src={menu} />
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </nav>
            <div className='container'>
                <h1 className='banner_heading'>Your Trusted Advisors</h1>
                <form className='banner_form'>
                    <a className='select_link' href=''>Services </a>
                    <a className='select_link' href=''>Credentials</a>
                    {/* <select name="Services">
                        <option value="">Services</option>

                    </select>
                    <select name="Credentials">
                        <option value="">Credentials</option>

                    </select> */}

                </form>

            </div>
            <button className='scroll_btn' onClick={scrollDown}></button>
        </div>

    );
}

export default TopHeader;
