import React from 'react';

function OurMission() {
    return (

        <section className="sec01 sec03" >
            <div className='container'>
                <artical className='sec01_content'>
                <div className='sec_01_left'>
                    <h2 className='heading'>Our Mission</h2>
                    <p className='sec_bold'>We partner with our clients to create value and achieve superior outcomes.</p>
                  
                </div>
                
                </artical>
                
            </div>
        </section>

    );
}
export default OurMission;