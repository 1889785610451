import transection01 from '../../src/Assests/images/transection01.png';
import transection02 from '../../src/Assests/images/transection02.png';
import transection03 from '../../src/Assests/images/transection03.png';
import React from "react";

const TransectionData = [
    {
        imgsrc: transection01
    },
    {
        imgsrc: transection02
    },
    {
        imgsrc: transection03
    },{
        imgsrc: transection01
    },
    {
        imgsrc: transection02
    },
    {
        imgsrc: transection03
    },{
        imgsrc: transection01
    },
    {
        imgsrc: transection02
    },
    {
        imgsrc: transection03
    }
    
]
export default TransectionData;