import news01 from '../../src/Assests/images/news_01.png';
import news02 from '../../src/Assests/images/news_02.png';
import news03 from '../../src/Assests/images/news_03.png';
import React from "react";

const NewsData = [
    {
        imgsrc: news01,
        title: 'Awad Capital advises The Luxury Closet on investment from Huda Beauty Investments',
        dicription: 'PwC Middle East enters into an asset purchase agreement to acquire Tyconz’s Enterprise Business Applications Operations which will significantly expand their technology and digital consulting services offering.',
        time: '02.09.2020',
        cat: 'INFORMATION TECHNOLOGY'
    },
    {
        imgsrc: news02,
        title: 'Awad Capital advises The Luxury Closet on investment from Huda Beauty Investments',
        time: '02.09.2020',
        cat: 'E-commerce'
    },
    {
        imgsrc: news03,
        title: 'Awad Capital advises The Luxury Closet on investment from Huda Beauty Investments',
        dicription: '',
        time: '02.09.2020',
        cat: 'E-commerce'
    }
]
export default NewsData;