import client0 from '../../src/Assests/images/client01.png';
import client01 from '../../src/Assests/images/client02.png';
import client02 from '../../src/Assests/images/client03.png';
import React from "react";

const ClientData = [
    {
        imgsrc: client0
    },
    {
        imgsrc: client01
    },
    {
        imgsrc: client02
    },{
        imgsrc: client0
    },
    {
        imgsrc: client01
    },
    {
        imgsrc: client02
    },{
        imgsrc: client0
    },
    {
        imgsrc: client01
    },
    {
        imgsrc: client02
    },
    {
        imgsrc: client0
    },
    {
        imgsrc: client01
    },
    {
        imgsrc: client02
    },{
        imgsrc: client0
    },
    {
        imgsrc: client01
    },
    {
        imgsrc: client02
    },{
        imgsrc: client0
    },
    {
        imgsrc: client01
    },
    {
        imgsrc: client02
    },
    {
        imgsrc: client0
    },
    {
        imgsrc: client01
    },
    {
        imgsrc: client02
    },
    {
        imgsrc: client0
    },
    {
        imgsrc: client01
    },
    {
        imgsrc: client02
    },{
        imgsrc: client0
    },
    {
        imgsrc: client01
    },
    {
        imgsrc: client02
    }
]
export default ClientData;